import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "../../../utils/Button/Button";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./SP.css";
import { useDispatch, useSelector } from "react-redux";
import { GetAllServicePreofessional } from "../../../../_actions/company_actions";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import Default from "./Default.png";
import {
  PO_VisitsCompanyProfileAnalytics,
  PO_VisitsCompanySocialMediaAnalytics,
  SP_VisitsCompanyProfileAnalytics,
  SP_VisitsCompanySocialMediaAnalytics,
} from "../../../../_actions/company_analytics";
import Filters from "../../../utils/Filters/Filters";
import Pagination from "../../../Pagination/Pagination";

export default function ServiceProfessionalPage({style}) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  const [ServiceProfessionals, setServiceProfessionals] = useState([]);
  const bodywidth = document.querySelector("body").scrollWidth;
  const [clientHeight, setclientHeight] = useState(Number);
  const [screenwidth, setscreenwidth] = useState(bodywidth);

  const [nodata, setnodata] = useState(false);

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  const GetServicePreofessionalData = (variables) => {
    dispatch(GetAllServicePreofessional(variables)).then((response) => {
      if (response.payload.ServiceProfessionals.length > 0) {
        const setdata = async () => {
          setServiceProfessionals(response.payload.ServiceProfessionals);
        };
        setdata().then(() => {
          const socialiconscontainers =
            document.querySelectorAll(".social-icons");

          for (let i = 0; i < socialiconscontainers.length; i++) {
            const socialicons = socialiconscontainers[i].childNodes;
            for (let j = 0; j < socialicons.length; j++) {
              socialicons[j].childNodes[0].style.transitionDelay = `0.${j + 1
                }s`;
            }
          }
        });

        setnodata(false);
      } else {
        setServiceProfessionals([]);
        setnodata(true);
      }
    });
  };

  useEffect(() => {
    let variables = {
      filter: false,
      State: "",
      City: "",
      Profession_Type: "",
    };
    GetServicePreofessionalData(variables);
  }, []);

  const OnHandleUserKnowDetailsButton = (companyId) => {
    let variables = {
      Source: "PO",
      Company: companyId,
      Visited_Date: new Date().toLocaleDateString(),
      Visited_Time: new Date().toTimeString(),
    };
    dispatch(PO_VisitsCompanyProfileAnalytics(variables)).then((response) => {
      // console.log("Response", response);
    });
  };

  const OnHandleCompanyKnowDetailsButton = (companyId) => {
    if (company.companyData._id !== companyId) {
      let variables = {
        Source: "SP",
        Company: companyId,
        Visited_Date: new Date().toLocaleDateString(),
        Visited_Time: new Date().toTimeString(),
      };
      dispatch(SP_VisitsCompanyProfileAnalytics(variables)).then((response) => {
        // console.log("Response", response);
      });
    }
  };

  const onHandleFacebookClick = (companyId, Social_Media) => {
    const variables = {
      Social_Media: Social_Media,
      Company: companyId,
      Visited_Date: new Date().toLocaleDateString(),
      Visited_Time: new Date().toTimeString(),
    };
    if (user.userData && user.userData.isAuth) {
      // console.log("Handle Facebook Click User", variables);
      dispatch(PO_VisitsCompanySocialMediaAnalytics(variables)).then(
        (response) => {
          // console.log("Response", response);
        }
      );
    }
    if (company.companyData && company.companyData.isAuth) {
      if (company.companyData._id !== companyId) {
        // console.log("Handle Facebook Click Company");
        dispatch(SP_VisitsCompanySocialMediaAnalytics(variables)).then(
          (response) => {
            // console.log("Response", response);
          }
        );
      }
    }
  };

  const getfiltersdata = (data) => {
    // console.log("Filter Data ", data);
    let variables = {
      filter: true,
      State: data.State,
      City: data.City,
      Profession_Type: data.ProjectType,
    };
    GetServicePreofessionalData(variables);
    // console.log(variables);
  };

  return (
    <>
      {/* <SignInRequired /> */}
      <div className="agents-container" style={style}>
        <PageBanner
          title="Service Professionals"
        />
        <div className="header">
          <p className="title">
            <b>Explore Our Featured Service Professionals</b>
          </p>
          <p
            style={{ width: screenwidth <= 650 ? screenwidth - 30 + "px" : null }}
            className="description"
          >
            Choose amongst the highly experienced, elite and the most loved
            professionals to work on your Property
          </p>
        </div>
        <Filters
          Location={true}
          ProjectType={2}
          ProjectTypeTitle="Profession Type"
          ChangedArray={[
            "Architect",
            "Engineer",
            "Contractor",
            "Interior Designer",
          ]}
          HideotherLists={true}
          getfiltersdata={getfiltersdata}
        />
        {ServiceProfessionals.length > 0 ? (
          <Pagination Collection={ServiceProfessionals}>
              {ServiceProfessionals.map((agent, index) => {
                return (
                  <div id="single-agent" className="agent" key={index}>
                  <div className="upper">
                    <div
                      style={{
                        height:
                          screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                      }}
                      className="imgandicons"
                    >
                      <img
                        style={{
                          width:
                            screenwidth <= 650
                              ? screenwidth - 40 + "px"
                              : null,
                          transform: agent.Company_Details
                            .Company_Cover_Picture
                            ? "scale(1)"
                            : "scale(0.8)",
                        }}
                        src={
                          agent.Company_Details.Company_Cover_Picture
                            ? agent.Company_Details.Company_Cover_Picture
                            : Default
                        }
                        alt={agent.Company_Details.Company_Cover_Picture}
                      />
                      <div id="social-icons" className="social-icons">
                        {agent.Contact_Details.Social_Media.Facebook ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Facebook}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Facebook")
                            }
                          >
                            <i id="fb" className="fab fa-facebook-f"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Instagram ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Instagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Instagram")
                            }
                          >
                            <i id="insta" className="fab fa-instagram"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Linkedin ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Linkedin")
                            }
                          >
                            <i id="linkedin" className="fab fa-linkedin"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Pinterest ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Pinterest")
                            }
                          >
                            <i id="pin" className="fab fa-pinterest-p"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Quora ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Quora}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Quora")
                            }
                          >
                            <i id="quora" className="fab fa-quora"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Reddit ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Reddit}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Reddit")
                            }
                          >
                            <i id="reddit" className="fab fa-reddit"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Twitter ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Twitter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Twitter")
                            }
                          >
                            <i id="twt" className="fab fa-twitter"></i>
                          </a>
                        ) : null}
                        {agent.Contact_Details.Social_Media.Youtube ? (
                          <a
                            href={`${agent.Contact_Details.Social_Media.Youtube}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              onHandleFacebookClick(agent._id, "Youtube")
                            }
                          >
                            <i id="youtube" className="fab fa-youtube"></i>
                          </a>
                        ) : null}
                      </div>
                    </div>
                    <div className="details">
                      <div className="addressandmail">
                        <p className="address">
                          S.P. Profile No.&nbsp;&nbsp;{agent._id}
                        </p>
                        {agent.Contact_Details.Main_Head_Office_Number.Company_WhatsApp ? 
                        (
                        <>
                        {/* <a href="https://api.whatsapp.com/send?phone=1234567890" target='_blank'>Contact Us on WhatsApp</a> */}
                        <a
                          href={`https://api.whatsapp.com/send?phone=${agent.Contact_Details.Main_Head_Office_Number.Company_WhatsApp}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "WhatsApp")
                          }
                        >
                          <i
                            id="mail-icon"
                            className="fa fa-whatsapp fa-2x"
                          ></i>
                        </a>
                        </>
                        ) : null}
                      </div>
                      <div className="address">
                        <p className="left-side">
                          Service Professional Name :
                        </p>
                        <p
                          style={{
                            color: "#fe5631",
                            fontSize: "18px",
                          }}
                          className="right-side"
                        >
                          <b>{agent.name}</b>
                        </p>
                      </div>
                      <div className="address">
                        <p className="left-side">Company Name :</p>
                        <p className="right-side">
                          {agent.Company_Details.Company_Name
                            ? agent.Company_Details.Company_Name
                            : "-----"}
                        </p>
                      </div>
                      {/* <p className="name">
                  <b>{agent.name}</b>
                </p> */}
                      {/* <p className="companyname">
                  <b>
                    {agent.Company_Details.Company_Name
                      ? agent.Company_Details.Company_Name
                      : "-----"}
                  </b>
                </p> */}
                      <div className="address">
                        <p className="left-side">Profession :</p>
                        <p className="right-side">
                          {agent.Professional_Details.Professional_Profession
                            ? agent.Professional_Details
                              .Professional_Profession
                            : "-----"}
                        </p>
                      </div>
                      {/* <p className="profession">
                  {agent.Professional_Details.Professional_Profession
                    ? agent.Professional_Details.Professional_Profession
                    : "-----"}
                </p> */}
                      <div className="address">
                        <p className="left-side">
                          No. of Uploaded Projects :
                        </p>
                        <p className="right-side">
                          {agent.TotalProjectsUploaded}
                        </p>
                      </div>
                      {/* <p className="properties">
                  No. of Uploaded Projects:&nbsp;
                  {agent.TotalProjectsUploaded}
                </p> */}
                      <div className="rating">
                        {/* <img
                          className="verified-img"
                          src={Verified}
                          alt="Verified"
                        /> */}
                        {/* <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <p>Average</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="telandbtn">
                  {agent.Contact_Details.Main_Head_Office_Number.User_Phone_Number === 0 ? 
                    <a>{agent.Contact_Details.Main_Head_Office_Number.User_Phone_Number}</a>
                    :
                    <a
                      className="tel"
                      href={`tel:${agent.PhNumber}`}
                      onClick={() =>
                        onHandleFacebookClick(agent._id, "PhoneCall")
                      }
                    >
                      <i className="fas fa-phone-alt"></i>
                      {
                        agent.Contact_Details.Main_Head_Office_Number
                          .User_Phone_Number
                      }
                    </a>
                  }
                    {user.userData && user.userData.isAuth ? (
                      <div
                        className="btn"
                        onClick={() =>
                          OnHandleUserKnowDetailsButton(agent._id)
                        }
                      >
                        <Button
                          width="160px"
                          background="#201c2d"
                          name="KNOW DETAILS"
                          directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                        />
                      </div>
                    ) : (
                      <>
                        {company.companyData && company.companyData.isAuth ? (
                          <div
                            className="btn"
                            onClick={() =>
                              OnHandleCompanyKnowDetailsButton(agent._id)
                            }
                          >
                            <Button
                              width="160px"
                              background="#201c2d"
                              name="KNOW DETAILS"
                              directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                            />
                          </div>
                        ) : (
                          <div className="btn">
                            <Button
                              width="160px"
                              background="#201c2d"
                              name="KNOW DETAILS"
                              directto={`/AllServiceProfessionalPage/Profile/OtherView/${agent._id}`}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                );
              })}
            </Pagination>
        ) : nodata ? (
          <p style={{
            textAlign: "center",
            fontSize: "17px",
            color: "gray",
            marginTop: "30px"
          }}>No Service Professionals Found !</p>
        ) : (
          <LoadingIcon />
        )}

        <br />

        <br />
        
      </div>
      
    </>


  );
}
