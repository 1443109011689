import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./ServiceProfessionals.css";
import Button from "../../../utils/Button/Button";
// import { properties } from "./Data";
// import { getAllProject } from "../../../../_actions/project_actions";
import { useDispatch, useSelector } from "react-redux";
import Default from "./Default.png";
// import Verified from "../../../utils/Verified/Verified.svg"

import { GetAllServicePreofessional } from "../../../../_actions/company_actions";
import {
  PO_VisitsCompanyProfileAnalytics,
  PO_VisitsCompanySocialMediaAnalytics,
  SP_VisitsCompanyProfileAnalytics,
  SP_VisitsCompanySocialMediaAnalytics,
} from "../../../../_actions/company_analytics";
import SignInRequired from "./SignInRequired/SignInRequired";

export default function ServiceProfessionals(props) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [featuredpropertywidth, setfeaturedpropertywidth] = useState();
  const [ServiceProfessionals, setServiceProfessionals] = useState([]);
  const [datareceived, setdatareceived] = useState(false);
  const propertyheightref = useRef(null);

  const getProduct = (variables) => {
    setdatareceived(false);

    dispatch(GetAllServicePreofessional(variables)).then((response) => {
      if (response.payload.success) {
        setServiceProfessionals(response.payload.ServiceProfessionals);

        setTimeout(() => {
          setfeaturedpropertywidth(
            propertyheightref.current.getBoundingClientRect().width
          );
        }, 20);

        setTimeout(() => {
          setdatareceived(true);
        }, 10);
      } else {
        alert("Failed to fetch product datas");
      }
    });
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  useEffect(() => {
    getProduct();
  }, []);

  const handlearrowclick = (e) => {
    const Featuredproperties = document.getElementById("sps-container");
    const transformwidth = parseInt(
      Featuredproperties.style.transform.replace(/[^\d.]/g, "")
    );
    // console.log(transformwidth);
    var featuredpropertieslength = ServiceProfessionals.length - 3;
    if (screenwidth <= 1260) {
      featuredpropertieslength = ServiceProfessionals.length - 2;
    }
    if (screenwidth <= 870) {
      featuredpropertieslength = ServiceProfessionals.length - 1;
    }
    if (e.currentTarget.className === "fas fa-arrow-left") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        Featuredproperties.style.transform = `translate(-${(featuredpropertywidth + 20) * featuredpropertieslength
          }px)`;
      } else {
        Featuredproperties.style.transform = `translate(-${transformwidth - featuredpropertywidth - 20
          }px)`;
      }
    }
    if (e.currentTarget.className === "fas fa-arrow-right") {
      if (transformwidth.toString() === "NaN" || transformwidth === 0) {
        Featuredproperties.style.transform = `translate(-${featuredpropertywidth + 20
          }px)`;
      }
      if (transformwidth > featuredpropertywidth * featuredpropertieslength) {
        Featuredproperties.style.transform = `translate(-${0}px)`;
      } else {
        Featuredproperties.style.transform = `translate(-${featuredpropertywidth + transformwidth + 20
          }px)`;
      }
    }
  };

  const OnHandleUserKnowDetailsButton = (companyId) => {
    // console.log("On Handle User Know Details Button ", companyId);
    // console.log("User Id ", user.userData._id);
    let variables = {
      Company: companyId,
      Visited_Date: new Date().toLocaleDateString(),
      Visited_Time: new Date().toTimeString(),
    };
    dispatch(PO_VisitsCompanyProfileAnalytics(variables)).then((response) => {
      // console.log("Response", response);
    });
  };

  const OnHandleCompanyKnowDetailsButton = (companyId) => {
    // console.log("On Handle Company Know Details Button ", companyId);
    // console.log("Company Id ", company.companyData._id);
    if (company.companyData._id != companyId) {
      let variables = {
        Company: companyId,
        Visited_Date: new Date().toLocaleDateString(),
        Visited_Time: new Date().toTimeString(),
      };
      dispatch(SP_VisitsCompanyProfileAnalytics(variables)).then((response) => {
        // console.log("Response", response);
      });
    }
  };

  const onHandleFacebookClick = (companyId, Social_Media) => {
    const variables = {
      Social_Media: Social_Media,
      Company: companyId,
      Visited_Date: new Date().toLocaleDateString(),
      Visited_Time: new Date().toTimeString(),
    };
    if (user.userData && user.userData.isAuth) {
      // console.log("Handle Facebook Click User", variables);
      dispatch(PO_VisitsCompanySocialMediaAnalytics(variables)).then(
        (response) => {
          // console.log("Response", response);
        }
      );
    }
    if (company.companyData && company.companyData.isAuth) {
      if (company.companyData._id != companyId) {
        // console.log("Handle Facebook Click Company");
        dispatch(SP_VisitsCompanySocialMediaAnalytics(variables)).then(
          (response) => {
            // console.log("Response", response);
          }
        );
      }
    }
  };



  return (
    <div className="service-professionalss-container">
      <h1 className="title">
        {" "}
        <b>Explore Our Featured Service Professionals</b>
      </h1>
      <p
        style={{ width: screenwidth <= 800 ? screenwidth - 10 + "px" : null }}
        className="description"
      >
        Choose amongst the highly experienced, elite and the most loved
        professionals to work on your Property{" "}
      </p>
      <div
        style={{
          width:
            screenwidth <= 870
              ? featuredpropertywidth + "px"
              : screenwidth <= 1260
                ? featuredpropertywidth * 2 + 20 + "px"
                : featuredpropertywidth * 3 + 20 + "px",
        }}
        id="arrows"
        className="arrows"
      >
        <i
          id="left-arrow"
          onClick={handlearrowclick}
          className="fas fa-arrow-left"
        ></i>
        <i
          id="right-arrow"
          onClick={handlearrowclick}
          className="fas fa-arrow-right"
        ></i>
      </div>
      <div
        className="service-professionals"
        style={{
          width:
            screenwidth <= 870
              ? featuredpropertywidth + "px"
              : screenwidth <= 1260
                ? featuredpropertywidth * 2 + 20 + "px"
                : featuredpropertywidth * 3 + 40 + "px",
        }}
      >
      {company.companyData && company.companyData.isAuth ?
      <>
        <div
          style={{
            width: screenwidth < 500 ? screenwidth + "px" : null,
          }}
          id="sps-container"
          className="properties"
        >
          {ServiceProfessionals.map((agent, index) => {
            return (
              <div
                style={{
                  width: screenwidth < 500 ? screenwidth + "px" : null,
                }}
                id="home-featured-property"
                className="agent"
                key={index}
                ref={propertyheightref}
              >
                <div className="upper">
                  <div
                    style={{
                      height:
                        screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                    }}
                    className="imgandicons"
                  >
                    <img
                      style={{

                        width: screenwidth < 500 ? screenwidth - 10 + "px" : null,
                        transform: agent.Company_Details.Company_Cover_Picture
                          ? "scale(1)"
                          : "scale(0.8)",
                      }}
                      src={
                        agent.Company_Details.Company_Cover_Picture
                          ? agent.Company_Details.Company_Cover_Picture
                          : Default
                      }
                      alt={agent.Company_Details.Company_Cover_Picture}
                    />
                    <div id="social-icons" className="social-icons">
                      {agent.Contact_Details.Social_Media.Facebook ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Facebook}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Facebook")
                          }
                        >
                          <i id="fb" className="fab fa-facebook-f"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Instagram ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Instagram}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Instagram")
                          }
                        >
                          <i id="insta" className="fab fa-instagram"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Linkedin ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Linkedin")
                          }
                        >
                          <i id="linkedin" className="fab fa-linkedin"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Pinterest ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Pinterest")
                          }
                        >
                          <i id="pin" className="fab fa-pinterest-p"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Quora ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Quora}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Quora")
                          }
                        >
                          <i id="quora" className="fab fa-quora"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Reddit ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Reddit}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Reddit")
                          }
                        >
                          <i id="reddit" className="fab fa-reddit"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Twitter ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Twitter}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Twitter")
                          }
                        >
                          <i id="twt" className="fab fa-twitter"></i>
                        </a>
                      ) : null}
                      {agent.Contact_Details.Social_Media.Youtube ? (
                        <a
                          href={`${agent.Contact_Details.Social_Media.Youtube}`}
                          target="_blank"
                          onClick={() =>
                            onHandleFacebookClick(agent._id, "Youtube")
                          }
                        >
                          <i id="youtube" className="fab fa-youtube"></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="details">
                    <div className="addressandmail">
                      <p className="address">
                        Profile No.&nbsp;&nbsp;{agent._id}
                      </p>
                      <a
                        href={`mailto:${agent.Email}`}
                        onClick={() =>
                          onHandleFacebookClick(agent._id, "WhatsApp")
                        }
                      >
                        <i id="mail-icon" className="fa fa-whatsapp"></i>
                      </a>
                    </div>
                    <div className="address">
                      <p className="left-side">Service Professional Name :</p>
                      <p
                        style={{
                          color: "#fe5631",
                          fontSize: "18px"
                        }}
                        className="right-side"
                      >
                        <b>{agent.name}</b>
                      </p>
                    </div>
                    <div className="address">
                      <p className="left-side">Company Name :</p>
                      <p

                        className="right-side"
                      >
                        {agent.Company_Details.Company_Name
                          ? agent.Company_Details.Company_Name
                          : "-----"}
                      </p>
                    </div>
                    {/* <p className="name">
                      <b>{agent.name}</b>
                    </p> */}
                    {/* <p className="companyname">
                      <b>
                        {agent.Company_Details.Company_Name
                          ? agent.Company_Details.Company_Name
                          : "-----"}
                      </b>
                    </p> */}
                    <div className="address">
                      <p className="left-side">Profession :</p>
                      <p

                        className="right-side"
                      >
                        {agent.Professional_Details.Professional_Profession
                          ? agent.Professional_Details.Professional_Profession
                          : "-----"}
                      </p>
                    </div>
                    {/* <p className="profession">
                      {agent.Professional_Details.Professional_Profession
                        ? agent.Professional_Details.Professional_Profession
                        : "-----"}
                    </p> */}
                    <div className="address">
                      <p className="left-side">No. of Uploaded Projects :</p>
                      <p

                        className="right-side"
                      >
                        {agent.TotalProjectsUploaded}
                      </p>
                    </div>
                    {/* <p className="properties">
                      No. of Uploaded Projects:&nbsp;
                      {agent.TotalProjectsUploaded}
                    </p> */}

                    {/* <img className="verified-img" src={Verified} alt="verified" /> */}
                    {/* <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <p>Average</p> */}
                  </div>
                </div>
                <div className="telandbtn">
                  <a
                    className="tel"
                    href={`tel:${agent.PhNumber}`}
                    onClick={() =>
                      onHandleFacebookClick(agent._id, "PhoneCall")
                    }
                  >
                    <i className="fas fa-phone-alt"></i>
                    {
                      agent.Contact_Details.Main_Head_Office_Number
                        .User_Phone_Number
                    }
                  </a>
                  {user.userData && user.userData.isAuth ? (
                    <div
                      className="btn"
                      onClick={() => OnHandleUserKnowDetailsButton(agent._id)}
                    >
                      <Button
                        width="160px"
                        background="#201c2d"
                        name="KNOW DETAILS"
                        directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                      />
                    </div>
                  ) : (
                    <>
                      {company.companyData && company.companyData.isAuth ? (
                        <div
                          className="btn"
                          onClick={() =>
                            OnHandleCompanyKnowDetailsButton(agent._id)
                          }
                        >
                          <Button
                            width="160px"
                            background="#201c2d"
                            name="KNOW DETAILS"
                            directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                          />
                        </div>
                      ) : (
                        <div className="btn">
                          <Button
                            width="160px"
                            background="#201c2d"
                            name="KNOW DETAILS"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
      :
      <>
          {user.userData && user.userData.isAuth ? 
            <>
              {user.userData && user.userData.isAuth && !user.userData.Profile_Details_Filled ? 
                <>
                <SignInRequired title={"Please Complete Your Profile Details"} directto={"/PropertyOwner/postpropertyrequirements"} ButtonTitle={"Complete Your Profile"} ShowButton={true} />
                <div
                    style={{
                      width: screenwidth < 500 ? screenwidth + "px" : null,
                      filter : "blur(5px)",
                      pointerEvents : "none"
                    }}
                    id="sps-container"
                    className="properties"
                  >
                    {ServiceProfessionals.map((agent, index) => {
                      return (
                        <div
                          style={{
                            width: screenwidth < 500 ? screenwidth + "px" : null,
                          }}
                          id="home-featured-property"
                          className="agent"
                          key={index}
                          ref={propertyheightref}
                        >
                          <div className="upper">
                            <div
                              style={{
                                height:
                                  screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                              }}
                              className="imgandicons"
                            >
                              <img
                                style={{

                                  width: screenwidth < 500 ? screenwidth - 10 + "px" : null,
                                  transform: agent.Company_Details.Company_Cover_Picture
                                    ? "scale(1)"
                                    : "scale(0.8)",
                                }}
                                src={
                                  agent.Company_Details.Company_Cover_Picture
                                    ? agent.Company_Details.Company_Cover_Picture
                                    : Default
                                }
                                alt={agent.Company_Details.Company_Cover_Picture}
                              />
                              <div id="social-icons" className="social-icons">
                                {agent.Contact_Details.Social_Media.Facebook ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Facebook}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Facebook")
                                    }
                                  >
                                    <i id="fb" className="fab fa-facebook-f"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Instagram ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Instagram}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Instagram")
                                    }
                                  >
                                    <i id="insta" className="fab fa-instagram"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Linkedin ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Linkedin")
                                    }
                                  >
                                    <i id="linkedin" className="fab fa-linkedin"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Pinterest ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Pinterest")
                                    }
                                  >
                                    <i id="pin" className="fab fa-pinterest-p"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Quora ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Quora}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Quora")
                                    }
                                  >
                                    <i id="quora" className="fab fa-quora"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Reddit ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Reddit}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Reddit")
                                    }
                                  >
                                    <i id="reddit" className="fab fa-reddit"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Twitter ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Twitter}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Twitter")
                                    }
                                  >
                                    <i id="twt" className="fab fa-twitter"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Youtube ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Youtube}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Youtube")
                                    }
                                  >
                                    <i id="youtube" className="fab fa-youtube"></i>
                                  </a>
                                ) : null}
                              </div>
                            </div>
                            <div className="details">
                              <div className="addressandmail">
                                <p className="address">
                                  Profile No.&nbsp;&nbsp;{agent._id}
                                </p>
                                <a
                                  href={`mailto:${agent.Email}`}
                                  onClick={() =>
                                    onHandleFacebookClick(agent._id, "WhatsApp")
                                  }
                                >
                                  <i id="mail-icon" className="fa fa-whatsapp"></i>
                                </a>
                              </div>
                              <div className="address">
                                <p className="left-side">Service Professional Name :</p>
                                <p
                                  style={{
                                    color: "#fe5631",
                                    fontSize: "18px"
                                  }}
                                  className="right-side"
                                >
                                  <b>{agent.name}</b>
                                </p>
                              </div>
                              <div className="address">
                                <p className="left-side">Company Name :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.Company_Details.Company_Name
                                    ? agent.Company_Details.Company_Name
                                    : "-----"}
                                </p>
                              </div>
                              {/* <p className="name">
                                <b>{agent.name}</b>
                              </p> */}
                              {/* <p className="companyname">
                                <b>
                                  {agent.Company_Details.Company_Name
                                    ? agent.Company_Details.Company_Name
                                    : "-----"}
                                </b>
                              </p> */}
                              <div className="address">
                                <p className="left-side">Profession :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.Professional_Details.Professional_Profession
                                    ? agent.Professional_Details.Professional_Profession
                                    : "-----"}
                                </p>
                              </div>
                              {/* <p className="profession">
                                {agent.Professional_Details.Professional_Profession
                                  ? agent.Professional_Details.Professional_Profession
                                  : "-----"}
                              </p> */}
                              <div className="address">
                                <p className="left-side">No. of Uploaded Projects :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.TotalProjectsUploaded}
                                </p>
                              </div>
                              {/* <p className="properties">
                                No. of Uploaded Projects:&nbsp;
                                {agent.TotalProjectsUploaded}
                              </p> */}

                              {/* <img className="verified-img" src={Verified} alt="verified" /> */}
                              {/* <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <p>Average</p> */}
                            </div>
                          </div>
                          <div className="telandbtn">
                            <a
                              className="tel"
                              href={`tel:${agent.PhNumber}`}
                              onClick={() =>
                                onHandleFacebookClick(agent._id, "PhoneCall")
                              }
                            >
                              <i className="fas fa-phone-alt"></i>
                              {
                                agent.Contact_Details.Main_Head_Office_Number
                                  .User_Phone_Number
                              }
                            </a>
                            {user.userData && user.userData.isAuth ? (
                              <div
                                className="btn"
                                onClick={() => OnHandleUserKnowDetailsButton(agent._id)}
                              >
                                <Button
                                  width="160px"
                                  background="#201c2d"
                                  name="KNOW DETAILS"
                                  directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                                />
                              </div>
                            ) : (
                              <>
                                {company.companyData && company.companyData.isAuth ? (
                                  <div
                                    className="btn"
                                    onClick={() =>
                                      OnHandleCompanyKnowDetailsButton(agent._id)
                                    }
                                  >
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                      directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                                    />
                                  </div>
                                ) : (
                                  <div className="btn">
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                </>
                :
                <>
                    {user.userData && user.userData.isAuth && user.userData.First_Property ? 
                    <>
                    <SignInRequired title={"Please Post Your First Property"} directto={"/PropertyOwner/postpropertyrequirements"} ButtonTitle={"Post Your First Property Requirement"} ShowButton={true} />
                    <div
                        style={{
                          width: screenwidth < 500 ? screenwidth + "px" : null,
                          filter : "blur(5px)",
                          pointerEvents : "none"
                        }}
                        id="sps-container"
                        className="properties"
                      >
                        {ServiceProfessionals.map((agent, index) => {
                          return (
                            <div
                              style={{
                                width: screenwidth < 500 ? screenwidth + "px" : null,
                              }}
                              id="home-featured-property"
                              className="agent"
                              key={index}
                              ref={propertyheightref}
                            >
                              <div className="upper">
                                <div
                                  style={{
                                    height:
                                      screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                                  }}
                                  className="imgandicons"
                                >
                                  <img
                                    style={{

                                      width: screenwidth < 500 ? screenwidth - 10 + "px" : null,
                                      transform: agent.Company_Details.Company_Cover_Picture
                                        ? "scale(1)"
                                        : "scale(0.8)",
                                    }}
                                    src={
                                      agent.Company_Details.Company_Cover_Picture
                                        ? agent.Company_Details.Company_Cover_Picture
                                        : Default
                                    }
                                    alt={agent.Company_Details.Company_Cover_Picture}
                                  />
                                  <div id="social-icons" className="social-icons">
                                    {agent.Contact_Details.Social_Media.Facebook ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Facebook}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Facebook")
                                        }
                                      >
                                        <i id="fb" className="fab fa-facebook-f"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Instagram ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Instagram}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Instagram")
                                        }
                                      >
                                        <i id="insta" className="fab fa-instagram"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Linkedin ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Linkedin")
                                        }
                                      >
                                        <i id="linkedin" className="fab fa-linkedin"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Pinterest ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Pinterest")
                                        }
                                      >
                                        <i id="pin" className="fab fa-pinterest-p"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Quora ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Quora}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Quora")
                                        }
                                      >
                                        <i id="quora" className="fab fa-quora"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Reddit ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Reddit}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Reddit")
                                        }
                                      >
                                        <i id="reddit" className="fab fa-reddit"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Twitter ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Twitter}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Twitter")
                                        }
                                      >
                                        <i id="twt" className="fab fa-twitter"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Youtube ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Youtube}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Youtube")
                                        }
                                      >
                                        <i id="youtube" className="fab fa-youtube"></i>
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="details">
                                  <div className="addressandmail">
                                    <p className="address">
                                      Profile No.&nbsp;&nbsp;{agent._id}
                                    </p>
                                    <a
                                      href={`mailto:${agent.Email}`}
                                      onClick={() =>
                                        onHandleFacebookClick(agent._id, "WhatsApp")
                                      }
                                    >
                                      <i id="mail-icon" className="fa fa-whatsapp"></i>
                                    </a>
                                  </div>
                                  <div className="address">
                                    <p className="left-side">Service Professional Name :</p>
                                    <p
                                      style={{
                                        color: "#fe5631",
                                        fontSize: "18px"
                                      }}
                                      className="right-side"
                                    >
                                      <b>{agent.name}</b>
                                    </p>
                                  </div>
                                  <div className="address">
                                    <p className="left-side">Company Name :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.Company_Details.Company_Name
                                        ? agent.Company_Details.Company_Name
                                        : "-----"}
                                    </p>
                                  </div>
                                  {/* <p className="name">
                                    <b>{agent.name}</b>
                                  </p> */}
                                  {/* <p className="companyname">
                                    <b>
                                      {agent.Company_Details.Company_Name
                                        ? agent.Company_Details.Company_Name
                                        : "-----"}
                                    </b>
                                  </p> */}
                                  <div className="address">
                                    <p className="left-side">Profession :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.Professional_Details.Professional_Profession
                                        ? agent.Professional_Details.Professional_Profession
                                        : "-----"}
                                    </p>
                                  </div>
                                  {/* <p className="profession">
                                    {agent.Professional_Details.Professional_Profession
                                      ? agent.Professional_Details.Professional_Profession
                                      : "-----"}
                                  </p> */}
                                  <div className="address">
                                    <p className="left-side">No. of Uploaded Projects :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.TotalProjectsUploaded}
                                    </p>
                                  </div>
                                  {/* <p className="properties">
                                    No. of Uploaded Projects:&nbsp;
                                    {agent.TotalProjectsUploaded}
                                  </p> */}

                                  {/* <img className="verified-img" src={Verified} alt="verified" /> */}
                                  {/* <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <p>Average</p> */}
                                </div>
                              </div>
                              <div className="telandbtn">
                                <a
                                  className="tel"
                                  href={`tel:${agent.PhNumber}`}
                                  onClick={() =>
                                    onHandleFacebookClick(agent._id, "PhoneCall")
                                  }
                                >
                                  <i className="fas fa-phone-alt"></i>
                                  {
                                    agent.Contact_Details.Main_Head_Office_Number
                                      .User_Phone_Number
                                  }
                                </a>
                                {user.userData && user.userData.isAuth ? (
                                  <div
                                    className="btn"
                                    onClick={() => OnHandleUserKnowDetailsButton(agent._id)}
                                  >
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                      directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {company.companyData && company.companyData.isAuth ? (
                                      <div
                                        className="btn"
                                        onClick={() =>
                                          OnHandleCompanyKnowDetailsButton(agent._id)
                                        }
                                      >
                                        <Button
                                          width="160px"
                                          background="#201c2d"
                                          name="KNOW DETAILS"
                                          directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                                        />
                                      </div>
                                    ) : (
                                      <div className="btn">
                                        <Button
                                          width="160px"
                                          background="#201c2d"
                                          name="KNOW DETAILS"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    </>
                    :
                    <>
                    {/* <SignInRequired style={{ display: 'none' }} title={"Please Complete Your Profile Details"} directto={"/PropertyOwner/postpropertyrequirements"} /> */}
                    <div
                        style={{
                          width: screenwidth < 500 ? screenwidth + "px" : null,
                        }}
                        id="sps-container"
                        className="properties"
                      >
                        {ServiceProfessionals.map((agent, index) => {
                          return (
                            <div
                              style={{
                                width: screenwidth < 500 ? screenwidth + "px" : null,
                              }}
                              id="home-featured-property"
                              className="agent"
                              key={index}
                              ref={propertyheightref}
                            >
                              <div className="upper">
                                <div
                                  style={{
                                    height:
                                      screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                                  }}
                                  className="imgandicons"
                                >
                                  <img
                                    style={{

                                      width: screenwidth < 500 ? screenwidth - 10 + "px" : null,
                                      transform: agent.Company_Details.Company_Cover_Picture
                                        ? "scale(1)"
                                        : "scale(0.8)",
                                    }}
                                    src={
                                      agent.Company_Details.Company_Cover_Picture
                                        ? agent.Company_Details.Company_Cover_Picture
                                        : Default
                                    }
                                    alt={agent.Company_Details.Company_Cover_Picture}
                                  />
                                  <div id="social-icons" className="social-icons">
                                    {agent.Contact_Details.Social_Media.Facebook ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Facebook}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Facebook")
                                        }
                                      >
                                        <i id="fb" className="fab fa-facebook-f"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Instagram ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Instagram}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Instagram")
                                        }
                                      >
                                        <i id="insta" className="fab fa-instagram"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Linkedin ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Linkedin")
                                        }
                                      >
                                        <i id="linkedin" className="fab fa-linkedin"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Pinterest ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Pinterest")
                                        }
                                      >
                                        <i id="pin" className="fab fa-pinterest-p"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Quora ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Quora}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Quora")
                                        }
                                      >
                                        <i id="quora" className="fab fa-quora"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Reddit ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Reddit}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Reddit")
                                        }
                                      >
                                        <i id="reddit" className="fab fa-reddit"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Twitter ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Twitter}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Twitter")
                                        }
                                      >
                                        <i id="twt" className="fab fa-twitter"></i>
                                      </a>
                                    ) : null}
                                    {agent.Contact_Details.Social_Media.Youtube ? (
                                      <a
                                        href={`${agent.Contact_Details.Social_Media.Youtube}`}
                                        target="_blank"
                                        onClick={() =>
                                          onHandleFacebookClick(agent._id, "Youtube")
                                        }
                                      >
                                        <i id="youtube" className="fab fa-youtube"></i>
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="details">
                                  <div className="addressandmail">
                                    <p className="address">
                                      Profile No.&nbsp;&nbsp;{agent._id}
                                    </p>
                                    <a
                                      href={`mailto:${agent.Email}`}
                                      onClick={() =>
                                        onHandleFacebookClick(agent._id, "WhatsApp")
                                      }
                                    >
                                      <i id="mail-icon" className="fa fa-whatsapp"></i>
                                    </a>
                                  </div>
                                  <div className="address">
                                    <p className="left-side">Service Professional Name :</p>
                                    <p
                                      style={{
                                        color: "#fe5631",
                                        fontSize: "18px"
                                      }}
                                      className="right-side"
                                    >
                                      <b>{agent.name}</b>
                                    </p>
                                  </div>
                                  <div className="address">
                                    <p className="left-side">Company Name :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.Company_Details.Company_Name
                                        ? agent.Company_Details.Company_Name
                                        : "-----"}
                                    </p>
                                  </div>
                                  {/* <p className="name">
                                    <b>{agent.name}</b>
                                  </p> */}
                                  {/* <p className="companyname">
                                    <b>
                                      {agent.Company_Details.Company_Name
                                        ? agent.Company_Details.Company_Name
                                        : "-----"}
                                    </b>
                                  </p> */}
                                  <div className="address">
                                    <p className="left-side">Profession :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.Professional_Details.Professional_Profession
                                        ? agent.Professional_Details.Professional_Profession
                                        : "-----"}
                                    </p>
                                  </div>
                                  {/* <p className="profession">
                                    {agent.Professional_Details.Professional_Profession
                                      ? agent.Professional_Details.Professional_Profession
                                      : "-----"}
                                  </p> */}
                                  <div className="address">
                                    <p className="left-side">No. of Uploaded Projects :</p>
                                    <p

                                      className="right-side"
                                    >
                                      {agent.TotalProjectsUploaded}
                                    </p>
                                  </div>
                                  {/* <p className="properties">
                                    No. of Uploaded Projects:&nbsp;
                                    {agent.TotalProjectsUploaded}
                                  </p> */}

                                  {/* <img className="verified-img" src={Verified} alt="verified" /> */}
                                  {/* <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <p>Average</p> */}
                                </div>
                              </div>
                              <div className="telandbtn">
                                <a
                                  className="tel"
                                  href={`tel:${agent.PhNumber}`}
                                  onClick={() =>
                                    onHandleFacebookClick(agent._id, "PhoneCall")
                                  }
                                >
                                  <i className="fas fa-phone-alt"></i>
                                  {
                                    agent.Contact_Details.Main_Head_Office_Number
                                      .User_Phone_Number
                                  }
                                </a>
                                {user.userData && user.userData.isAuth ? (
                                  <div
                                    className="btn"
                                    onClick={() => OnHandleUserKnowDetailsButton(agent._id)}
                                  >
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                      directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {company.companyData && company.companyData.isAuth ? (
                                      <div
                                        className="btn"
                                        onClick={() =>
                                          OnHandleCompanyKnowDetailsButton(agent._id)
                                        }
                                      >
                                        <Button
                                          width="160px"
                                          background="#201c2d"
                                          name="KNOW DETAILS"
                                          directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                                        />
                                      </div>
                                    ) : (
                                      <div className="btn">
                                        <Button
                                          width="160px"
                                          background="#201c2d"
                                          name="KNOW DETAILS"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    </>
                    }
                </>
              }
            </>
          :
                <>
                <SignInRequired title={"Kindly Login to view the Service Professional Profiles and get in touch with them"} directto={"/"} ShowButton={false} />
                <div
                    style={{
                      width: screenwidth < 500 ? screenwidth + "px" : null,
                      filter : "blur(5px)",
                      pointerEvents : "none"
                    }}
                    id="sps-container"
                    className="properties"
                  >
                    {ServiceProfessionals.map((agent, index) => {
                      return (
                        <div
                          style={{
                            width: screenwidth < 500 ? screenwidth + "px" : null,
                          }}
                          id="home-featured-property"
                          className="agent"
                          key={index}
                          ref={propertyheightref}
                        >
                          <div className="upper">
                            <div
                              style={{
                                height:
                                  screenwidth <= 650 ? screenwidth - 90 + "px" : null,
                              }}
                              className="imgandicons"
                            >
                              <img
                                style={{

                                  width: screenwidth < 500 ? screenwidth - 10 + "px" : null,
                                  transform: agent.Company_Details.Company_Cover_Picture
                                    ? "scale(1)"
                                    : "scale(0.8)",
                                }}
                                src={
                                  agent.Company_Details.Company_Cover_Picture
                                    ? agent.Company_Details.Company_Cover_Picture
                                    : Default
                                }
                                alt={agent.Company_Details.Company_Cover_Picture}
                              />
                              <div id="social-icons" className="social-icons">
                                {agent.Contact_Details.Social_Media.Facebook ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Facebook}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Facebook")
                                    }
                                  >
                                    <i id="fb" className="fab fa-facebook-f"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Instagram ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Instagram}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Instagram")
                                    }
                                  >
                                    <i id="insta" className="fab fa-instagram"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Linkedin ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Linkedin}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Linkedin")
                                    }
                                  >
                                    <i id="linkedin" className="fab fa-linkedin"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Pinterest ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Pinterest}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Pinterest")
                                    }
                                  >
                                    <i id="pin" className="fab fa-pinterest-p"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Quora ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Quora}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Quora")
                                    }
                                  >
                                    <i id="quora" className="fab fa-quora"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Reddit ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Reddit}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Reddit")
                                    }
                                  >
                                    <i id="reddit" className="fab fa-reddit"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Twitter ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Twitter}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Twitter")
                                    }
                                  >
                                    <i id="twt" className="fab fa-twitter"></i>
                                  </a>
                                ) : null}
                                {agent.Contact_Details.Social_Media.Youtube ? (
                                  <a
                                    href={`${agent.Contact_Details.Social_Media.Youtube}`}
                                    target="_blank"
                                    onClick={() =>
                                      onHandleFacebookClick(agent._id, "Youtube")
                                    }
                                  >
                                    <i id="youtube" className="fab fa-youtube"></i>
                                  </a>
                                ) : null}
                              </div>
                            </div>
                            <div className="details">
                              <div className="addressandmail">
                                <p className="address">
                                  Profile No.&nbsp;&nbsp;{agent._id}
                                </p>
                                <a
                                  href={`mailto:${agent.Email}`}
                                  onClick={() =>
                                    onHandleFacebookClick(agent._id, "WhatsApp")
                                  }
                                >
                                  <i id="mail-icon" className="fa fa-whatsapp"></i>
                                </a>
                              </div>
                              <div className="address">
                                <p className="left-side">Service Professional Name :</p>
                                <p
                                  style={{
                                    color: "#fe5631",
                                    fontSize: "18px"
                                  }}
                                  className="right-side"
                                >
                                  <b>{agent.name}</b>
                                </p>
                              </div>
                              <div className="address">
                                <p className="left-side">Company Name :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.Company_Details.Company_Name
                                    ? agent.Company_Details.Company_Name
                                    : "-----"}
                                </p>
                              </div>
                              {/* <p className="name">
                                <b>{agent.name}</b>
                              </p> */}
                              {/* <p className="companyname">
                                <b>
                                  {agent.Company_Details.Company_Name
                                    ? agent.Company_Details.Company_Name
                                    : "-----"}
                                </b>
                              </p> */}
                              <div className="address">
                                <p className="left-side">Profession :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.Professional_Details.Professional_Profession
                                    ? agent.Professional_Details.Professional_Profession
                                    : "-----"}
                                </p>
                              </div>
                              {/* <p className="profession">
                                {agent.Professional_Details.Professional_Profession
                                  ? agent.Professional_Details.Professional_Profession
                                  : "-----"}
                              </p> */}
                              <div className="address">
                                <p className="left-side">No. of Uploaded Projects :</p>
                                <p

                                  className="right-side"
                                >
                                  {agent.TotalProjectsUploaded}
                                </p>
                              </div>
                              {/* <p className="properties">
                                No. of Uploaded Projects:&nbsp;
                                {agent.TotalProjectsUploaded}
                              </p> */}

                              {/* <img className="verified-img" src={Verified} alt="verified" /> */}
                              {/* <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <p>Average</p> */}
                            </div>
                          </div>
                          <div className="telandbtn">
                            <a
                              className="tel"
                              href={`tel:${agent.PhNumber}`}
                              onClick={() =>
                                onHandleFacebookClick(agent._id, "PhoneCall")
                              }
                            >
                              <i className="fas fa-phone-alt"></i>
                              {
                                agent.Contact_Details.Main_Head_Office_Number
                                  .User_Phone_Number
                              }
                            </a>
                            {user.userData && user.userData.isAuth ? (
                              <div
                                className="btn"
                                onClick={() => OnHandleUserKnowDetailsButton(agent._id)}
                              >
                                <Button
                                  width="160px"
                                  background="#201c2d"
                                  name="KNOW DETAILS"
                                  directto={`/AllServiceProfessionalPage/SP/Profile/OtherView/${agent._id}`}
                                />
                              </div>
                            ) : (
                              <>
                                {company.companyData && company.companyData.isAuth ? (
                                  <div
                                    className="btn"
                                    onClick={() =>
                                      OnHandleCompanyKnowDetailsButton(agent._id)
                                    }
                                  >
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                      directto={`/ServiceProfessional/Profile/OtherView/${agent._id}`}
                                    />
                                  </div>
                                ) : (
                                  <div className="btn">
                                    <Button
                                      width="160px"
                                      background="#201c2d"
                                      name="KNOW DETAILS"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                </>
          }
      </>
      }
        
      </div>
      <div style={{ display: props.btn }} className="viewallapartment">
        <Button
          width="auto"
          background="#fe5631"
          name="VIEW ALL SERVICE PROFESSIONALS"
          directto="/AllServiceProfessionalPage"
        />
      </div>
      <br />
      <br />
      <br />

    </div>
  );
}
