import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../../utils/Button/Button";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./Property.css";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import { withRouter } from "react-router-dom";
import Filters from "../../../utils/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperty } from "../../../../_actions/property_actions";
import Apartment from "../../../asserts/Property_Requirement_Images/Apartment.jpg";
import Bungalow from "../../../asserts/Property_Requirement_Images/Bungalow.jpg";
import Healthcare from "../../../asserts/Property_Requirement_Images/Healthcare.jpg";
import Hospitality from "../../../asserts/Property_Requirement_Images/Hospitality.jpg";
import Independent_Home from "../../../asserts/Property_Requirement_Images/Independent_Home.jpg";
import Industrial from "../../../asserts/Property_Requirement_Images/Industrial.jpg";
import Institutional from "../../../asserts/Property_Requirement_Images/Institutional.jpg";
import Office_Corporate_and_Tech from "../../../asserts/Property_Requirement_Images/Office_Corporate_and_Tech.jpg";
import Penthouse from "../../../asserts/Property_Requirement_Images/Penthouse.jpg";
import Retail from "../../../asserts/Property_Requirement_Images/Retail.jpg";
import Stand_Alone_Building from "../../../asserts/Property_Requirement_Images/Stand_Alone_Building.jpg";
import Villa from "../../../asserts/Property_Requirement_Images/Villa.jpg";
import InteriorDefaultImage from "../../../asserts/Property_Requirement_Images/InteriorDefaultImage.jpg";
import soldout from "./Soldout.png";
import ValidityExpire from "./Validity_Expired.svg";
import NQA_Replacement from "./NQA_Replacement.png"
import Pagination from "../../../Pagination/Pagination";
import GoogleLogin from "react-google-login";
import { loginCompanyGoogle } from "../../../../_actions/company_actions";
import MarqueeBanner from "../../../utils/MarqueeBanner/MarqueeBanner";

function Property(props) {
  const dispatch = useDispatch();
  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  const [datareceived, setdatareceived] = useState(false);
  const [propertyheight, setpropertyheight] = useState(null);
  const [NoProduct, setNoProduct] = useState(false);
  const [showModal, setshowModal] = useState(false);


  const propertyheightref = useRef(null);
  const company = useSelector((state) => state.company);
  const [Products, setProducts] = useState([]);

  const getProduct = (variables) => {
    setdatareceived(false);
    dispatch(getAllProperty(variables)).then((response) => {
      if (response.payload.success) {
        if (response.payload.products.length > 0) {
          setNoProduct(false);
          setProducts(response.payload.products.reverse());
        } else {
          setNoProduct(true);
          setTimeout(() => {
            setpropertyheight(0);
          }, 20);
        }
        setTimeout(() => {
          setdatareceived(true);
        }, 10);
      } else {
        alert("Failed to fetch product datas");
      }
    });
  };

  useEffect(() => {
  }, [Products.length]);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
    });
  }, [screenwidth]);

  useEffect(() => {
    let variables = {
      filter: false,
      State: "",
      City: "",
      Project_Type: "",
      BuildingType: "",
    };
    getProduct(variables);
  }, []);

  const getfiltersdata = (data) => {
    let variables = {
      filter: true,
      State: data.State,
      City: data.City,
      Project_Type: data.ProjectType,
      BuildingType: data.ProjectSubType,
    };
    getProduct(variables);
  };

  const openModal = () => {
    console.log("Open modal");
    setshowModal(true);
  }

  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  })

  const handleLogin = (googleData) => {
    dispatch(loginCompanyGoogle(googleData))
    .then(response => {
      if (response.payload.loginSuccess) {
        window.localStorage.setItem('companyId', response.payload.companyId);
        props.history.push("/ServiceProfessional/PropertyRequirements");
      } else {
        alert('Check out your Account or Password again')
      }
    })
  }

  const showRequirements = (value) => {
    if(!value) {
      return "Will discuss on call";
    }
    // console.log("Description: " , value);
    return value.length <= 250 ? value : value.substring(0, 250) + "...";
  }

  return (
    <div className="Property-req-container">
      <div style={ showModal ? { filter: "blur(5px)", pointerEvents: "none" } : {}}>
        <PageBanner
          title="Leads"
        />
        <MarqueeBanner text="Buy 1 Lead, Get 2 Replacement Leads - No Questions Asked, with the NebourHood Guarantee! For more details, click here" />
        <br />
        <br />

        <div className="properties-featured-properties-container">
          <div className="titleanddesc">
            <p className="title">
              <b>Explore Our Prime Property Requirements</b>
            </p>
            <p
              style={{
                width: screenwidth <= 510 ? screenwidth - 50 + "px" : null,
              }}
              className="desc"
            >
              Kindly select the Property Requirements that you would love to work on, from the following catalogue
            </p>
          </div>
          <Filters
            Location={true}
            ProjectType={2}
            ProjectTypeTitle="Property Requirement Type"
            getfiltersdata={getfiltersdata}
          />
          <br/>
          {datareceived ? (
            NoProduct ? (
              <div className="no-product-found">
                <p>No Property Requirements Found !</p>
              </div>
            ) : (
              
              <Pagination Collection={Products}>
              {Products.map((property, index) => {
                return (
                  <div
                  id="featured-properties-property"
                  className="Featured-properties-property"
                  ref={propertyheightref}
                  key={index}
                >
                  <div className="upper">
                    {property.sold > 0 ?
                      <>
                        {property.Validity_Till <= 0 ?
                          <img className="leads-sold-out" src={ValidityExpire} alt="sold-out" />
                          :
                          <>
                          {company.companyData && company.companyData.isAuth && (company.companyData.currentPackage === "Baby Tiger" || company.companyData.currentPackage === "Startup Tiger Prime" || company.companyData.currentPackage === "Elite Tiger Prime" || company.companyData.currentPackage === "Classic Tiger Prime" || company.companyData.currentPackage === "Baby Tiger Prime" || company.companyData.currentPackage === "Startup Tiger" || company.companyData.currentPackage === "Elite Tiger" || company.companyData.currentPackage === "Classic Tiger") ?
                              <>
                              {property.sold != 3 ?
                                <img className="leads-sold-out" src={soldout} alt="sold-out" />
                              :
                              null
                            }
                              </>
                            :
                            null
                          }
                          </>
                        }
                      </>
                      :
                      <>
                        {property.sold <= 0 ?
                          <img className="leads-sold-out" src={soldout} alt="sold-out" />
                          :
                          null
                        }
                      </>
                    }
                    <img className="nqa-replacement" src={NQA_Replacement} alt="sold-out" />
                    {property.Project_Type === "Interior" ? (
                      <p className="tag" id="interior-tag">
                        INTERIOR
                      </p>
                    ) : (
                      <p className="tag" id="construction-tag">
                        CONSTRUCTION
                      </p>
                    )}
                    {property.Area_Type === "Apartment" ? (
                      <img
                        className="upper-img" src={Apartment} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Bungalow" ? (
                      <img
                        className="upper-img" src={Bungalow} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Penthouse" ? (
                      <img
                        className="upper-img" src={Penthouse} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Institutional" ? (
                      <img
                        className="upper-img" src={Institutional} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Hospitality" ? (
                      <img
                        className="upper-img" src={Hospitality} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Healthcare" ? (
                      <img
                        className="upper-img" src={Healthcare} alt="featured-property" />
                    ) : null}
                    {property.Area_Type ===
                      "Office - Corporate and Tech" ? (
                      <img
                        className="upper-img"
                        src={Office_Corporate_and_Tech}
                        alt="featured-property"
                      />
                    ) : null}
                    {property.Area_Type === "Industrial" ? (
                      <img
                        className="upper-img" src={Industrial} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Retail" ? (
                      <img
                        className="upper-img" src={Retail} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Villa" ? (
                      <img
                        className="upper-img" src={Villa} alt="featured-property" />
                    ) : null}
                    {property.Area_Type === "Independent Home" ? (
                      <img
                        className="upper-img"
                        src={Independent_Home}
                        alt="featured-property"
                      />
                    ) : null}
                    {property.Area_Type === "Stand Alone Building" ? (
                      <img
                        className="upper-img"
                        src={Stand_Alone_Building}
                        alt="featured-property"
                      />
                      ) : 
                      null
                      // <img
                      //   className="upper-img"
                      //   src={InteriorDefaultImage}
                      //   alt="featured-property"
                      // />
                    }
                    {property.Area_Type === "Farmhouse" || property.Area_Type === "Other" ? (
                      <img
                        className="upper-img"
                        src={InteriorDefaultImage}
                        alt="featured-property"
                      />
                      ) : 
                      null
                    }
                    {property.BuildingType != null ? 
                    <p className="price">{property.BuildingType}</p>
                    : null }
                  </div>
                  <div className="middle">
                    <div className="address">
                      <p
                        className="title"
                        style={{ color: "black", minWidth: "70px" }}
                      >
                        Address :
                      </p>
                      <p className="addrss">
                        {property.Land_Building}, {property.State},{" "}
                        {property.City}, {property.Area_Name}, {property.Pin_Code}
                      </p>
                    </div>
                    <div className="address">
                      <p className="title" style={{ color: "black" }}>
                        Property Owner Name :
                      </p>
                      <b
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        {property.Name_Of_property_Owner}
                      </b>
                    </div>
                    <div className="address">
                      {/* <p className="title" style={{ color: "black" }}>
                        Requirements :
                      </p> */}
                      <p className="addrss">
                        {showRequirements(property.description)}
                        </p>
                    </div>
                    {property.Area_Type != null ? 
                    <p className="apartmentandsize">
                      {property.Area_Type}&nbsp;({property.Project_Area}{" "}
                      sq.ft.)
                    </p>
                      : null}
                    <div
                      className="other-details"
                    >
                      <div className="dtl" style={{ borderLeft: "none" }}>
                        <p>
                          {property.Service_Type_Required
                            ? property.Service_Type_Required
                            : "---"}
                        </p>
                      </div>
                      <div className="border"></div>
                      <div className="dtl">
                        {/* <p>
                          {property.Beginning_The_Project
                            ? property.Beginning_The_Project
                            : "---"}
                        </p> */}
                        <p>
                          {property.Beginning_The_Project
                           ?
                          <>
                          {
                          property.Validity_Till <= 30 ? "Needed Immediately" :
                            property.Validity_Till <= 45 ? "Within 2 Months" : 
                            property.Validity_Till <= 75 ? "Within 3 Months" : 
                            property.Validity_Till <= 105 ? "Within 4 Months" : 
                            property.Validity_Till <= 135 ? "Within 5 Months" : 
                            property.Validity_Till <= 165 ? "Within 6 Months" : 
                            property.Validity_Till <= 195 ? "Within 7 Months" : 
                            property.Validity_Till <= 225 ? "Within 8 Months" : 
                            property.Validity_Till <= 255 ? "Within 9 Months" : 
                            property.Validity_Till <= 285 ? "Within 10 Months" : 
                            property.Validity_Till <= 315 ? "Within 11 Months" : 
                            property.Validity_Till <= 345 ? "Within 12 Months" :
                            "---"
                          }
                          </>
                           : "---"}
                        </p>

                      </div>
                      <div className="border"></div>
                      <div className="dtl">
                        {property.sold >= 1 ?
                        <>
                        {company.companyData && company.companyData.isAuth && company.companyData.Subscribed && (company.companyData.currentPackage === "Baby Tiger" || company.companyData.currentPackage === "Startup Tiger Prime" || company.companyData.currentPackage === "Elite Tiger Prime" || company.companyData.currentPackage === "Classic Tiger Prime" || company.companyData.currentPackage === "Baby Tiger Prime" || company.companyData.currentPackage === "Elite Tiger" || company.companyData.currentPackage === "Classic Tiger"|| company.companyData.currentPackage === "Startup Tiger") ?
                        <>
                          {property.sold === 3 ? 
                        <b style={{color: 'green'}}> 
                          In Stock
                        </b>
                          : 
                          <b style={{color: 'red'}}> 
                            Out of Stock
                            </b>
                          }
                        </>
                        : 
                        <b style={{color: 'green'}}> In Stock: {property.sold}</b> 
                      }
                      </>
                        : 
                        <b style={{color: 'red'}}>Out of Stock</b>
                        }
                          {/* In Stock: {property.sold <= 0 ? 0 : property.sold} */}
                          {/* {property.Property_Condition
                            ? property.Property_Condition
                            : "---"} */}
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="rating">
                    {company.companyData && company.companyData.isAuth && company.companyData.Subscribed && company.companyData.currentPackage !== "" ? 
                    null 
                      : 
                    <>
                    {company.companyData && company.companyData.isAuth && company.companyData.Membership ? 
                    <>
                      <p style={{color: 'red', marginLeft: '15px', fontSize: '1.5em', fontWeight: '700'}}>
                      <del>₹ 449</del>
                      </p>
                      <p style={{color: 'green', marginLeft: '15px', fontSize: '1.5em', fontWeight: '700'}}>
                        ₹ 324
                      </p>
                    </>
                      :
                      <>
                      <p style={{color: 'red', marginLeft: '15px', fontSize: '1.5em', fontWeight: '700'}}>
                      <del>₹ 524</del>
                      </p>
                      <p style={{color: 'green', marginLeft: '15px', fontSize: '1.5em', fontWeight: '700'}}>
                        ₹ 399
                      </p>
                    </>
                    }
                      
                    </> }
                    </div>
                    <div className="knowmore-btn">
                      {company.companyData && company.companyData.isAuth ? 
                      <Button
                        width="150px"
                        name="KNOW DETAILS"
                        directto={`/ServiceProfessional/PropertyRequirementsDetails/${property._id}`}
                        background="#201c2d"
                      />
                      :
                      <div onClick={()=>openModal()}>
                        <Button
                          width="150px"
                          name="KNOW DETAILS"
                          background="#201c2d"
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
                );
              })}
            </Pagination>
            )
          ) : (
            <LoadingIcon />
          )}
        </div>
        <br />
        <br />
      </div>
      {showModal ? 
      <div>
      <div className='sign-in-required-modall'>
        <div>
          <h1>Kindly login to view the lead details!</h1>
        </div>
        <div style={{border: '2px solid lightgrey'}}>
          <GoogleLogin 
                  clientId="737789981008-3kqljeg4a44qdbbiib27e9d8rtr8m34o.apps.googleusercontent.com"
                  buttonText="Log in with Google"
                  onSuccess={handleLogin}
                  // onFailure={handleLoginFail}
                  cookiePolicy={'single_host_origin'}
                />
        </div>
        <span className="close-icon" onClick={()=>setshowModal(false)}>
          Close
        </span>
      </div>
      </div>
      :
      null
    }
    </div>
  );
}

export default withRouter(Property);
