import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../../utils/Button/Button";
import PageBanner from "../../../utils/PageBanner/PageBanner";
import "./Projects.css";
// import NavigationNumbers from "../../../utils/NavigationNumbers/NavigationNumbers";
import LoadingIcon from "../../../utils/LoadingIcon/LoadingIcon";
import Filters from "../../../utils/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../../../_actions/project_actions";
import SmallCarousel from "../../../utils/SmallCarousel/SmallCarousel";
import Pagination from "../../../Pagination/Pagination";

export default function Projects() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);

  const bodywidth = document.querySelector("body").scrollWidth;

  const [screenwidth, setscreenwidth] = useState(bodywidth);
  // const [propertyheight, setpropertyheight] = useState();
  // const [randomdata, setrandomdata] = useState([]);
  const [datareceived, setdatareceived] = useState(false);
  const [propertyheight, setpropertyheight] = useState(null);
  const [NoProduct, setNoProduct] = useState(false);

  const propertyheightref = useRef(null);
  const [Projects, setProjects] = useState([]);

  const getProduct = (variables, filters) => {
    setdatareceived(false);

    dispatch(getAllProject(variables)).then((response) => {
      if (response.payload.success) {
        setProjects(response.payload.projects.reverse());
        if (response.payload.projects.length > 0) {
          // console.log(response.payload.projects);
          setNoProduct(false);
          setTimeout(() => {
            setpropertyheight(
              propertyheightref.current.getBoundingClientRect().height
            );
          }, 20);
        } else {
          setNoProduct(true);
          setTimeout(() => {
            setpropertyheight(0);
          }, 20);
        }
        setTimeout(() => {
          setdatareceived(true);
        }, 10);

        // console.log("Product Response", response.payload.projects);
      } else {
        alert("Failed to fetch product datas");
      }
    });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setscreenwidth(window.innerWidth);
      // console.log(screenwidth);
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [screenwidth]);

  const heighttomove =
    Projects.length <= 6 && Projects.length > 3 && screenwidth > 1050
      ? propertyheight * 2 + 20
      : Projects.length <= 3 && screenwidth > 1050
        ? propertyheight
        : Projects.length <= 3 && screenwidth < 1050 && screenwidth > 650
          ? propertyheight * 2 + 40
          : Projects.length >= 6 && Projects.length <= 9
            ? propertyheight * 3 + 60
            : propertyheight * 4 + 80;

  useEffect(() => {
    let variables = {
      filter: false,
      State: "",
      City: "",
      Project_Type: "",
      BuildingType: "",
    };
    getProduct(variables, false);
  }, []);

  const getfiltersdata = (data) => {
    let variables = {
      filter: true,
      State: data.State,
      City: data.City,
      Project_Type: data.ProjectType,
      BuildingType: data.ProjectSubType,
    };
    getProduct(variables, true);
  };

  return (
    <div className="Projects-container">
      <div>
        <PageBanner
          title="Projects"
        />
        <br />

        <br />
        <div className="properties-featured-properties-container">
          <div className="titleanddesc">
            <p className="title">
              <b>Explore Our Spectacular Projects</b>
            </p>
            <p
              style={{
                width: screenwidth <= 510 ? screenwidth - 50 + "px" : null,
              }}
              className="desc"
            >
              View fabulous Projects completed by skilled professionals
            </p>
          </div>
          <Filters
            Location={true}
            ProjectType={2}
            getfiltersdata={getfiltersdata}
          />
          {datareceived ? (
            NoProduct ? (
              <div className="no-product-found">
              <p
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  color: "gray",
                  marginTop: "25px",
                }}
              >
              No Projects Found!
              </p>
              </div>
            ) : (
              // <div className="Featured-properties-container">
              //   <div
              //     style={{
              //       height: heighttomove,
              //     }}
              //     id="Featured-properties"
              //     className="Featured-properties"
              //   >
              //     {Projects.map((property, index) => {
              //       return (
              //         <div
              //           id="featured-properties-property"
              //           className="featured-properties-property"
              //           ref={propertyheightref}
              //           key={index}
              //           style={{
              //             width:
              //               screenwidth <= 510 ? screenwidth - 10 + "px" : null,
              //           }}
              //         >
              //           <div className="upper">
              //             {property.Project_Type === "Interior" ? (
              //               <p className="tag" id="interior-tag">
              //                 INTERIOR
              //               </p>
              //             ) : (
              //               <p className="tag" id="construction-tag">
              //                 CONSTRUCTION
              //               </p>
              //             )}
              //             {/* <img
              //               style={{
              //                 width:
              //                   screenwidth <= 510
              //                     ? screenwidth - 30 + "px"
              //                     : null,
              //               }}
              //               src="https://templates.envytheme.com/fido/default/assets/images/featured/featured-2.jpg"
              //               alt="featured-property"
              //             /> */}
              //             <p className="price">
              //               {property.Project_Type_Subcategory_1}
              //             </p>
              //             <SmallCarousel
              //               Images={property.Images}
              //               style={{
              //                 width:
              //                   screenwidth <= 510
              //                     ? screenwidth - 30 + "px"
              //                     : null,
              //               }}
              //             />
              //           </div>
              //           <div
              //             style={{
              //               width:
              //                 screenwidth <= 510
              //                   ? screenwidth - 20 + "px"
              //                   : null,
              //             }}
              //             className="middle"
              //           >
              //             <div className="address">
              //               <p className="left-side">Address :</p>
              //               <p
              //                 style={{
              //                   color: "#fe5631",
              //                 }}
              //                 className="right-side"
              //               >
              //                 {" "}
              //                 {property.Address.House_Building_Plot_Land},{" "}
              //                 {property.Address.State}, {property.Address.City},{" "}
              //                 {property.Address.Areaname}
              //               </p>
              //             </div>
              //             <div className="address">
              //               <p className="left-side">Project Name :</p>
              //               <p
              //                 style={{
              //                   fontSize: "19.5px",
              //                 }}
              //                 className="right-side"
              //               >
              //                 {/* {" "}
              //                 {property.Address.House_Building_Plot_Land},{" "}
              //                 {property.Address.State}, {property.Address.City},{" "}
              //                 {property.Address.Areaname} */}
              //                 {property.Project_Name}
              //               </p>
              //             </div>
              //             <div className="address">
              //               <p className="left-side">
              //                 Service Professional Name :
              //               </p>
              //               <p className="right-side">
              //                 {property.WriterDetails.Service_Professional_Name}
              //               </p>
              //             </div>
              //             <div className="address">
              //               <p className="left-side">Company Name :</p>
              //               <p className="right-side">
              //                 {property.WriterDetails.Company_Name}
              //                 {/* Company */}
              //               </p>
              //             </div>
              //             <div className="address">
              //               <p className="left-side">Project No. :</p>
              //               <p
              //                 style={{
              //                   color: "#fe5631",
              //                 }}
              //                 className="right-side"
              //               >
              //                 {property._id}
              //               </p>
              //             </div>
              //             <div className="address">
              //               <p className="left-side">Property Type :</p>
              //               <p className="right-side">
              //                 {property.Project_Type_Subcategory_2}
              //               </p>
              //             </div>
              //             {/* <h2 className="property-title">
              //               {property.Project_Name}
              //             </h2> */}
              //             {/* <p className="apartmentandsize">
              //               {"Apartment"}&nbsp;({property.Project_Area} sq.ft.)
              //             </p> */}
              //             <div
              //               style={{
              //                 width:
              //                   screenwidth <= 510
              //                     ? screenwidth - 20 + "px"
              //                     : null,
              //               }}
              //               className="other-detailss-container"
              //             >
              //               <p>{property.Service_Requirement}</p>
              //               <div className="border"></div>
              //               <p>
              //                 Year of Completion: {property.Project_Completion}
              //               </p>
              //               <div className="border"></div>
              //               <p> No. of Images: {property.Images.length}</p>
              //             </div>
              //           </div>
              //           <div className="bottom">
              //             <div className="rating">
              //               {/* <div className="stars">
              //                 <span className="material-icons">star</span>
              //                 <span className="material-icons">star</span>
              //                 <span className="material-icons">star</span>
              //                 <span className="material-icons">star</span>
              //                 <span className="material-icons">star</span>
              //               </div> */}
              //               <div className="rating-name">
              //                 {/* {" "}
              //                 {5 > 4 ? "Excellent" : "Average"} */}
              //                 Uploaded on: {property.Uploaded_Date}
              //               </div>
              //             </div>
              //             <div className="knowmore-btn">
              //               {company.companyData &&
              //                 company.companyData.isAuth ? (
              //                 <Button
              //                   width="150px"
              //                   name="KNOW DETAILS"
              //                   directto={`/ServiceProfessional/ProjectsDetails/${property._id}`}
              //                   background="#201c2d"
              //                 />
              //               ) : (
              //                 <>
              //                   {user.userData && user.userData.isAuth ? (
              //                     <Button
              //                       width="150px"
              //                       name="KNOW DETAILS"
              //                       directto={`/PropertyOwner/ProjectsDetails/${property._id}`}
              //                       background="#201c2d"
              //                     />
              //                   ) : (
              //                     <Button
              //                       width="150px"
              //                       name="KNOW DETAILS"
              //                       directto={`/ProjectsDetails/${property._id}`}
              //                       background="#201c2d"
              //                     />
              //                   )}
              //                 </>
              //               )}
              //             </div>
              //           </div>
              //         </div>
              //       );
              //     })}
              //   </div>
              // </div>
              <Pagination Collection={Projects}>
              {Projects.map((property, index) => {
                return (
                  <div
                        id="featured-properties-property"
                        className="featured-properties-property"
                        ref={propertyheightref}
                        key={index}
                        style={{
                          width:
                            screenwidth <= 510 ? screenwidth - 10 + "px" : null,
                        }}
                      >
                        <div className="upper">
                          {property.Project_Type === "Interior" ? (
                            <p className="tag" id="interior-tag">
                              INTERIOR
                            </p>
                          ) : (
                            <p className="tag" id="construction-tag">
                              CONSTRUCTION
                            </p>
                          )}
                          {/* <img
                            style={{
                              width:
                                screenwidth <= 510
                                  ? screenwidth - 30 + "px"
                                  : null,
                            }}
                            src="https://templates.envytheme.com/fido/default/assets/images/featured/featured-2.jpg"
                            alt="featured-property"
                          /> */}
                          <p className="price">
                            {property.Project_Type_Subcategory_1}
                          </p>
                          <SmallCarousel
                            Images={property.Images}
                            style={{
                              width:
                                screenwidth <= 510
                                  ? screenwidth - 30 + "px"
                                  : null,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width:
                              screenwidth <= 510
                                ? screenwidth - 20 + "px"
                                : null,
                          }}
                          className="middle"
                        >
                          <div className="address">
                            <p className="left-side">Address :</p>
                            <p
                              style={{
                                color: "#fe5631",
                              }}
                              className="right-side"
                            >
                              {" "}
                              {property.Address.House_Building_Plot_Land},{" "}
                              {property.Address.State}, {property.Address.City},{" "}
                              {property.Address.Areaname}
                            </p>
                          </div>
                          <div className="address">
                            <p className="left-side">Project Name :</p>
                            <p
                              style={{
                                fontSize: "19.5px",
                              }}
                              className="right-side"
                            >
                              {/* {" "}
                              {property.Address.House_Building_Plot_Land},{" "}
                              {property.Address.State}, {property.Address.City},{" "}
                              {property.Address.Areaname} */}
                              {property.Project_Name}
                            </p>
                          </div>
                          <div className="address">
                            <p className="left-side">
                              Service Professional Name :
                            </p>
                            <p className="right-side">
                              {property.WriterDetails.Service_Professional_Name}
                            </p>
                          </div>
                          <div className="address">
                            <p className="left-side">Company Name :</p>
                            <p className="right-side">
                              {property.WriterDetails.Company_Name}
                              {/* Company */}
                            </p>
                          </div>
                          <div className="address">
                            <p className="left-side">Project No. :</p>
                            <p
                              style={{
                                color: "#fe5631",
                              }}
                              className="right-side"
                            >
                              {property._id}
                            </p>
                          </div>
                          <div className="address">
                            <p className="left-side">Property Type :</p>
                            <p className="right-side">
                              {property.Project_Type_Subcategory_2}
                            </p>
                          </div>
                          {/* <h2 className="property-title">
                            {property.Project_Name}
                          </h2> */}
                          {/* <p className="apartmentandsize">
                            {"Apartment"}&nbsp;({property.Project_Area} sq.ft.)
                          </p> */}
                          <div
                            style={{
                              width:
                                screenwidth <= 510
                                  ? screenwidth - 20 + "px"
                                  : null,
                            }}
                            className="other-detailss-container"
                          >
                            <p>{property.Service_Requirement}</p>
                            <div className="border"></div>
                            <p>
                              Year of Completion: {property.Project_Completion}
                            </p>
                            <div className="border"></div>
                            <p> No. of Images: {property.Images.length}</p>
                          </div>
                        </div>
                        <div className="bottom">
                          <div className="rating">
                            {/* <div className="stars">
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                              <span className="material-icons">star</span>
                            </div> */}
                            <div className="rating-name">
                              {/* {" "}
                              {5 > 4 ? "Excellent" : "Average"} */}
                              Uploaded on: {property.Uploaded_Date}
                            </div>
                          </div>
                          <div className="knowmore-btn">
                            {company.companyData &&
                              company.companyData.isAuth ? (
                              <Button
                                width="150px"
                                name="KNOW DETAILS"
                                directto={`/ServiceProfessional/ProjectsDetails/${property._id}`}
                                background="#201c2d"
                              />
                            ) : (
                              <>
                                {user.userData && user.userData.isAuth ? (
                                  <Button
                                    width="150px"
                                    name="KNOW DETAILS"
                                    directto={`/PropertyOwner/ProjectsDetails/${property._id}`}
                                    background="#201c2d"
                                  />
                                ) : (
                                  <Button
                                    width="150px"
                                    name="KNOW DETAILS"
                                    directto={`/ProjectsDetails/${property._id}`}
                                    background="#201c2d"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                );
              })}
            </Pagination>
            )
          ) : (
            <LoadingIcon />
          )}
        </div>
        <br />
        {/* {datareceived ? (
          !NoProduct ? (
            <NavigationNumbers
              numberofitems={12}
              screenwidthfornav1={1200}
              numberofitems1={8}
              screenwidthfornav2={750}
              numberofitems2={4}
              itemscontainerid="Featured-properties"
              heighttomove={heighttomove}
            />
          ) : null
        ) : null} */}
        <br />
      </div>
    </div>
  );
}
