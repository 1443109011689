export const filters = {
  Location: {
    States: ["Delhi", "Gujarat", "Karnataka", "Maharashtra", "National Capital Region (NCR)", "Tamil Nadu", "Telangana", "West Bengal"],
    Cities: [
    {
      State: "Karnataka",
      Cities: ["Bangalore"],
    },
    {
      State: "Gujarat",
      Cities: ["Ahmedabad"],
    },
    {
      State: "Tamil Nadu",
      Cities: ["Chennai"],
    },
    {
      State: "Delhi",
      Cities: ["Delhi"],
    },
    {
      State: "National Capital Region (NCR)",
      Cities: ["Faridabad", "Ghaziabad", "Greater Noida", "Gurgaon", "Meerut", "Noida", "Panipat", "Sonipat"],
    },
    {
      State: "Telangana",
      Cities: ["Hyderabad"],
    },
    {
      State: "West Bengal",
      Cities: ["Kolkata"],
    },
    {
      State: "Maharashtra",
      Cities: ["Mumbai", "Pune"],
    },
  ],
  },
  PSR: ["Only Designs", "Only Execution", "Both - Designs & Execution"],
  ProjectType: {
    Types: ["Interior", "Construction"],
    SubTypes: ["Residential", "Commercial"],
    Residential: [
      "Apartment",
      "Penthouse",
      "Stand Alone Building",
      "Independent Home",
      "Villa",
      "Bungalow",
    ],
    Commercial: [
      "Retail",
      "Hospitality",
      "Healthcare",
      "Office – Corporate and Tech",
      "Institutional",
      "Industrial",
    ],
    SpacesServed: {
      Residential: [
        "Bathroom",
        "Bedroom",
        "Disabled / Handicap – Accessible Designs & Services",
        "Entertainment",
        "Fitness",
        "Health & Safety",
        "Kitchen and Dining",
        "Living",
        "Multi - Faith Space",
        "Outdoor",
        "Passage Ways",
        "Productivity",
        "Security Systems",
        "Utility",
      ],
      Commercial: [
        "Retail",
        "Hospitality - (Accommodation, Food & Beverage, Travel & Tourism)",
        "Healthcare",
        "Office - Corporate & Tech",
        "Industrial",
        "Institutional",
      ],
      Institutional: [
        "Educational Sector",
        "Financial Sector",
        "Media & Entertainment Sector",
        "Research & Development Sector",
        "Transportation Sector",
      ],
    },
  },
};
